.main-info-contact{
  background-color: #282828;
}
.info-contact{
  margin-top: 0px;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: white;
  padding: 10px 20px;
}
.info-contact div{
  display: block;
  width: 200px;
  padding: 0px 0px;
  text-align: left;
}
.info-contact div a{
  text-decoration: none;
  color: white;
}
@media (max-width: 400px ) {
  .ds4-title{
    font-size: 30px;
  }
  .navbar{
    padding: 20px 20px ;
  }
}