.main-content-contact{
  background-image: url('../../../assets/img/backgrounk1920x1080.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
  padding-bottom: 100px;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: justify;
}
.btn-color{
  background-color: #09315c;
  border: none;
  color: white;

  
}
.btn-color:hover{
  background-color: #0963c4;
  color: white;
}

.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.img-item {
  width: 150px;
}
.span-item {
  
  margin-left: 50px;
  margin-right: 100px;
}

.container-margin-and-padding {
  margin: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
@media only screen and (max-width: 600px) {
  .main-content-contact {
    flex-direction:column;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .img-item {
    display: none;
  }

  .span-item {
    margin: 0px;
  }

  .container-margin-and-padding {
    margin: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}


.popup-close{
  display: none;
}
.popup-container{
  background-color: rgba(0, 0, 0, 0.509);
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0px;
  left:0px ;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}
.popup-contente{
  display: flex;
  border: solid #09315c;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 2%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  color: black;
  width: 500px;
  min-width: 300px;
  height: 300px;
}
#btn-close-popup{
  border-radius: 50% !important;
}

