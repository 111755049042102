
.navbar{
  width: 100%;
  padding: 10px 100px ;
  height: 10vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(234, 232, 232);
}
.ds4-title{
  font-weight: bold;
  font-size: 40px;
  text-decoration: none;
  color: #09315c;
}
.ds4-title:hover{
  color:rgb(2, 64, 85);
}
.imglogo{
  width: 120px;
}
nav a{
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 20px;
  margin-left: 10px;
  padding: 5px 15px;
  border: solid transparent;
  border-radius: 10px;
}
nav a:hover{
  /* box-shadow:
  2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
  6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
  12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
  22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
  41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
  100px 100px 80px rgba(0, 0, 0, 0.07)
  ;
   */
  border-color: #09315c ;
  color: black;
  transition: 0.4s;
}
.group-navbar{
  display: flex;
  align-items: center;
  gap: 10px;
}
span.menuBars{
  display: none;
  font-size: 40px;
  cursor: pointer;
}

nav.mobile-menu{
  transition: 0.5s;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.461);
  border-radius: 0px 0px 20px 20px;
  align-items: center;
  gap: 20px;
  padding: 20px;
  display: none;
}
nav.menuNone{
  display: none;
  /* opacity: 0; */
}

.sf-partner-badge-image {
  width: 160px;
  margin-left: 50px
}

@media (max-width: 1200px ) {
  nav.navmenu{
    display: none;
  }

  .ds4-title{
    display: none;
  }

  nav.mobile-menu{
    display: flex;
    
  }
  span.menuBars{
    display: block;
  }
  .menu-idiomas{
    display:none !important;
  }
  .sf-partner-badge-image {
    width: 100px;
  }
}

@media (max-width: 430px ) {
  .ds4-title{
    display: none;
  }
  .navbar{
    padding: 20px 20px ;
  }
  .sf-partner-badge-image {
    width: 100px;
  }
}
@media (max-width: 650px ) {
  .navbar{
    padding: 10px 30px ;
  }
  .ds4-title{
    display: none;
  }
  .imglogo{
    width: 70px;
  }
  .sf-partner-badge-image {
    width: 100px;
  }
}
@media (max-width: 320px ) {
  .ds4-title{
    display: none;
  }
  .imglogo{
    width: 70px;
  }
  .sf-partner-badge-image {
    width: 100px;
  }
}